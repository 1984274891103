import React, { Component } from 'react';
import Slider from 'react-slick';
import UserInfo from './UserInfo';
import s from './styles.module.scss';

import UserPhotoTwo from './../../Assets/Images/google_reviews/pic-12.png';
import UserPhotoThirteen from './../../Assets/Images/google_reviews/pic-11.png';
import UserPhotoFive from './../../Assets/Images/google_reviews/pic-09.png';
import UserPhotoSix from './../../Assets/Images/google_reviews/pic-05.png';
import UserPhotoTen from './../../Assets/Images/google_reviews/pic-13.png';

export const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
        {
            breakpoint: 769,
            settings: {
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const AGMTestimonials = [
  {
    id: 1,
    person: "Ronald L.",
    user: {
      name: "Ronald L.",
      photo: UserPhotoFive,
    },
    // link:
    //   "https://www.google.com/maps/contrib/112181701610826035537/reviews",
    text:
      "Top notch work! Courteous & professional staff. Quality products & finishes. Attention to detail. Project started one month late (my bad... I forgot to get a  building permit) and AGM accommodated us by not cashing our post dated cheques until work started up again!! At different stages you are consulted when design / construction / finish changes are needed at critical stages (I have had previous project contractors make unilateral decisions with less than desirable outcomes). I would strongly recommend AGM... they will be a bit more than their competition however their expertise, adhere to to the latest building codes,  tradespeople are second to none.",
  },
  {
    id: 2,
    user: {
      name: "Valerie A.",
      photo: UserPhotoTwo,
    },
    // link:
    //   "https://www.google.com/maps/contrib/109797041282582010158/reviews",
    text:
      "We hired AGM to create a small basement suite for our son.  From start to finish it was the best experience.  From the design phase, to the costing, to the actual construction I couldn’t have ask for anything more. Everyone was very very professional and accommodating.",
  },
  {
    id: 3,
    user: {
      name: "Andrew M.",
      photo: UserPhotoSix,
    },
    // link:
    //   "https://www.google.com/maps/contrib/111409753237468327941/reviews",
    text:
      "We recently had our basement finished by AGM. We felt very comfortable throughout the project. Their workers strive to do their best and work diligently! It does show in the final finished basement. Our entire family is in agreement that AGM did a great job not only to meet our expectations but in fact exceeded it! We definitely would recommend AGM to everyone!",
  },
  {
    id: 4,
    user: {
      name: "Srikanth R.",
      photo: UserPhotoTen,
    },
    // link:
    //   "https://www.google.com/maps/contrib/105734596933682635891/reviews",
    text:
      "AGM is a mark of great workmanship! We are very pleased with our finished basement living space. The process was streamlined and the work progressed from start to finish in a very professional manner! There were no surprises as every aspect of the work was planned at the design stage! We thank all the trades people for their wonderful work in our basement! Keep up the good work!",
  },
  {
    id: 5,
    user: {
      name: "Fazal H",
      photo: UserPhotoThirteen,
    },
    // link:
    //   "https://www.google.com/maps/contrib/103488205300508486662/reviews",
    text:
      "We have a very nice basement now! AGM workers did a great job to finish as per our requirements. All the staff and workers were very professional to deal with and we have had an excellent experience dealing with them during the process. Very experienced tradesmen and what a great company to work with! They do care about their clients and go to a great length to make sure the work is completed in a very timely and professional manner. We are very pleased to recommend AGM for basement finishing work to all homeowners who need their basement finished",
  },
];

export class ReviewsSlider extends Component {
    static defaultProps = {
        reviews: AGMTestimonials,
        className: '',
    };

    render() {
        const { reviews, className } = this.props;

        return (
            <Slider className={`${s.Slider} ${className}`} {...sliderSettings}>
                {reviews.map((review, index) => (
                    <div className={s.Slider_item} key={review.id} data-number={`0${index + 1}`}>
                      <p className={s.Slider_review}>
                        {
                          review.text
                        }
                      </p>
                      <UserInfo user={review.user} />
                    </div>
                ))}
            </Slider>
        );
    }
}

export default ReviewsSlider;
