import React from 'react';
import s from './styles.module.scss';

function UserInfo({ user, className = '', ...rest }) {
    return (
        <div className={`${s.User} ${className}`} {...rest}>
            <img
                src={user.photo}
                alt="person who left review"
                className={s.User_photo}
            />
            <div className={s.User_info}>
                <h6 className={s.User_name}>{user.name}</h6>
                <div className={s.User_rating}>
                    <i className='fa fa-star' />
                    <i className='fa fa-star' />
                    <i className='fa fa-star' />
                    <i className='fa fa-star' />
                    <i className='fa fa-star' />
                </div>
            </div>
        </div>
    );
}

export default UserInfo;
