import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import station1 from "../Assets/Images/Stations/5d08f0f9f1f8a5247a3c8318580cfra.png";
import station2 from "../Assets/Images/Stations/640toronto_tile.jpg";
import station3 from "../Assets/Images/Stations/680logo-1200x627.png";
import station4 from "../Assets/Images/Stations/940x400_edge 102.1.png";
import station5 from "../Assets/Images/Stations/1200px-1310_NEWS_logo.svg 1310 news.png";
import station6 from "../Assets/Images/Stations/1200px-CHCH2010.png";
import station7 from "../Assets/Images/Stations/CP24_Channel.png";
import station8 from "../Assets/Images/Stations/CTV_flat_logo.png";
import station9 from "../Assets/Images/Stations/KiSS-925-logo-514x514-1.png";
import station10 from "../Assets/Images/Stations/logos_radio_hamilton_900chml.png";
import station11 from "../Assets/Images/Stations/nwAD7PxQTK q107.png";
import station12 from "../Assets/Images/Stations/radio_newstalk610.png";

export default class SwipeToSlide extends Component {
  render() {
    const settings = {
      className: "center",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 8,
      slidesToScroll: 4,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 3000,
      cssEase: "linear",
      arrows: false,
      responsive: [
        {
          breakpoint: 1525,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 4,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            cssEase: "linear",
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            cssEase: "linear",
          },
        },
      ],
      afterChange: function (index) {
        // console.log(
        //   `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        // );
      },
    };
    return (
      <div className="stationSlider">
        <h4 style={{ textAlign: "center", margin: "0", color: "grey" }}>
          {" "}
          AS FEATURED ON
        </h4>
        <Slider {...settings}>
          <div className="stationImg cfra">
            <img src={station1} />
          </div>
          <div className="stationImg toronto">
            <img src={station2} />
          </div>
          <div className="stationImg stationNews">
            <img src={station3} />
          </div>
          <div className="stationImg stationRadio">
            <img src={station4} />
          </div>
          <div className="stationImg toronto">
            <img src={station5} />
          </div>
          <div className="stationImg chch">
            <img src={station6} />
          </div>
          <div className="stationImg cp">
            <img src={station7} />
          </div>
          <div className="stationImg">
            <img src={station8} />
          </div>
          <div className="stationImg kiss">
            <img src={station9} />
          </div>
          <div className="stationImg hamilton">
            <img src={station10} />
          </div>
          <div className="stationImg Qstation">
            <img src={station11} />
          </div>
          <div className="stationImg newsTalk">
            <img src={station12} />
          </div>
        </Slider>
      </div>
    );
  }
}
